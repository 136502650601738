import { motion } from "framer-motion";
import Tu from "../assets/tu.jpg";
import Hi from "../assets/hi.jpg";
import { Helmet } from "react-helmet-async";

const Ourteam = () => {
  return (
    <section id="" className="pt-24 text-myBlack sm:w-[80%] mx-auto">
      <Helmet>
        <title>DSpace - Our Team</title>
        <link rel="canonical" href="/ourteam" />
      </Helmet>
      <motion.div
        className=""
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true, amount: 0.5 }}
        transition={{ duration: 0.5 }}
        variants={{
          hidden: { opacity: 0, x: -50 },
          visible: { opacity: 1, x: 0 },
        }}
      >
        <div className="text-center">
          <div className="">
            <p className="font-bold text-[29px] sm:text-[35px] md:text-[40px] highlight max-w-fit mx-auto mb-5 text-myBlack">
              Our Committed Team
            </p>
            <p className="mt-3 font-bold text-[16px] sm:text-[18px]">
              "We can do big things with small team"
            </p>
          </div>
        </div>

        <div className="mt-10">
          <div
            className="flex flex-col gap-5"
            initial="hidden"
            whileInView="visible"
            viewport={{ once: true, amount: 0.5 }}
            transition={{ duration: 0.5 }}
            variants={{
              hidden: { opacity: 0, y: 50 },
              visible: { opacity: 1, y: 0 },
            }}
          >
            <div className="mission flex flex-col-reverse max-h-[400px] sm:flex-row-reverse bg-white p-2 rounded-md justify-between gap-5 items-center">
              <div className="flex flex-col basis-[60%] gap-0">
                <p className="font-bold text-xl sm:text-2xl mb-5">
                  TUSHAR SONI
                </p>
                <p className="text-[17px] font-semibold">[Co-Founder]</p>
                <p className="text-[17px] font-semibold">
                  SMM, Graphic Designer, Digital strategist
                </p>
              </div>

              <img
                loading="lazy"
                src={Tu}
                className="photo-1 w-full flex basis-[35%]"
                alt=""
                srcset=""
              />
            </div>
      
            <div className="mission flex flex-col-reverse max-h-[400px] sm:flex-row-reverse bg-white p-2 rounded-md justify-between sm:items-center gap-5">
              <div className="flex flex-col basis-[60%] gap-0">
                <p className="font-bold text-xl sm:text-2xl mb-5">
                  HIMANSHU KUMAR
                </p>
                <p className="text-[17px] font-semibold">
                  [Associate Director]
                </p>
                <p className="text-[17px] font-semibold">
                  Video Director, Strategist
                </p>
              </div>

              <div className="flex justify-center  basis-[35%]">
                <img
                  loading="lazy"
                  src={Hi}
                  className="photo-2 w-fit"
                  alt=""
                  srcset=""
                />
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default Ourteam;
